import React from "react";

import DocumentationPageLayout from "@src/components/DocumentationPageLayout";

function ThirdPartyServiceProviders({ pageContext }) {
  return (
    <DocumentationPageLayout
      content={pageContext.thirdPartyServiceProviders}
      title="Third Party Service Providers"
      seoTitle="page_titles.thirdPartyServiceProviders"
      seoDescription="page_descriptions.thirdPartyServiceProviders"
      type="third"
    />
  );
}

export default ThirdPartyServiceProviders;
